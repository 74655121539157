import React, { useEffect, useRef } from 'react'
import Footer from './Footer'
import Menu from './Menu'
import Particle from './Particles'
import phone from "../assets/phone.png"
import mail from "../assets/mail.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs from '@emailjs/browser';


function Contact() {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_c3w8ou9', 'template_rdgyxjb', form.current, 'G5pDDRgyAGcG6ireN')
            .then((result) => {
                console.log(result.text);
                alert("Form is submitted successfully.")
                window.location.reload();
            }, (error) => {
                console.log(error.text);
            });
    };

    useEffect(() => {
        AOS.init({
            duration: 600,
            once: false,
            easing: 'ease'
        });
    }, [])

    return (

        <div className="bg-black-50 text-white-100 font-poppins">

            <div className='lg:px-20 px-6 container mx-auto relative z-20'>
                <Menu />
                <Particle />

                <div>
                    <h1 data-aos='slide-right' className='lg:text-7xl text-4xl font-bold font-grotesk lg:py-12 pt-12'>
                        Contact
                    </h1>

                    <div className='lg:flex items-end justify-between gap-x-6 lg:py-12 py-6 lg:space-y-0 space-y-6'>

                        <div className='lg:w-1/3'>
                            <p data-aos='slide-right' className='lg:text-4xl text-3xl font-bold font-grotesk'>
                                Let’s get in touch!
                            </p>
                        </div>

                        <div className='lg:flex items-center justify-between gap-x-5 lg:space-y-0 space-y-6'>
                            <div data-aos='flip-left' className='p-8 service-gradient rounded-2xl'>
                                <img src={phone} alt='call' className='mb-12 p-3 rounded-full border border-white-200'></img>

                                <div className='space-y-2'>
                                    <p className='text-lg font-semibold font-grotesk'>
                                        Phone
                                    </p>
                                    <p className='lg:text-xl text-lg font-semibold font-grotesk'>
                                        +61 236-788-429
                                    </p>
                                </div>
                            </div>

                            <div data-aos='flip-right' className='p-8 service-gradient rounded-2xl'>
                                <img src={mail} alt='mail' className='mb-12 p-3 rounded-full border border-white-200'></img>

                                <div className='space-y-2'>
                                    <p className='text-lg font-semibold font-grotesk'>
                                        Email
                                    </p>
                                    <p className='lg:text-xl text-lg font-semibold font-grotesk'>
                                        support@Kickstartlabs.com
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='lg:flex flex-col items-center justify-center mx-auto lg:w-8/12 lg:py-20 py-6'>
                        <div className='bg-seagreen-100 rounded-3xl lg:p-10 p-6'>

                            <div className='mb-8'>
                                <h2 className='lg:text-4xl text-2xl font-semibold text-black-50'>
                                    Send Us Message
                                </h2>
                                <p className='pt-3'>Have any questions regarding our services? Send us your message.</p>
                            </div>

                            <form ref={form} onSubmit={sendEmail} className='space-y-6'>
                                <div className='lg:flex items-center justify-between gap-x-6 lg:space-y-0 space-y-8'>
                                    <div data-aos='fade-right' className='space-y-3 w-full'>
                                        <label className='text-xl font-bold text-black-50'>Name</label>
                                        <input name="user_name" type='text' required placeholder='Enter Your Name ...' className='lg:px-5 px-3 lg:py-4 py-3 rounded-xl bg-white-100 w-full focus:outline-black-50 text-black-50'></input>
                                    </div>

                                    <div data-aos='fade-left' className='space-y-3 w-full'>
                                        <label className='text-xl font-bold text-black-50'>Email</label>
                                        <input name="user_email" type='email' required placeholder='Enter Your Email Address ...' className='lg:px-5 px-3 lg:py-4 py-3 rounded-xl bg-white-100 w-full focus:outline-black-50 text-black-50'></input>
                                    </div>
                                </div>

                                <div data-aos='fade-up' className='space-y-3'>
                                    <label className='text-xl font-bold text-black-50'>Message</label>
                                    <textarea name="message" required placeholder='Enter Your Message ...' className='lg:px-5 px-3 lg:py-4 py-2 rounded-xl bg-white-100 w-full focus:outline-black-50 text-black-50' rows='6'></textarea>
                                </div>

                                <div className='lg:flex items-center justify-between gap-x-8 lg:space-y-0 space-y-6'>

                                    <button data-aos='zoom-in' type="submit" value="Send" className='lg:px-6 px-4 lg:py-3 py-2 bg-black-50 rounded-lg text-xl font-bold'>
                                        SUBMIT
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>


            </div>
            <Footer />
        </div>
    )
}

export default Contact
