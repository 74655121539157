import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import team_1 from '../assets/team_1.png';
import team_2 from '../assets/team_2.png';
import team_3 from '../assets/team_3.png';

function ImageSlider() {
    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        arrows: false,
        cssEase: "linear",
        autoplaySpeed: 2000,

        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                centerMode: true
            }

        }, {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                dots: true,
                infinite: true,

            }
        }, {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: false,
                infinite: true,
                autoplay: true,
                autoplaySpeed: 2000,
            }
        }]
    }



    const slider = React.useRef(null);

    return (
        <div className="">

            <Slider ref={slider} {...settings}>

                <div className='rounded-xl group cursor-pointer card-wrapper col-span-1'>
                    <img src={team_1} alt='' className='group-hover:opacity-70 transition-all group-hover:duration-500'></img>

                    <div className='flex items-center justify-between mt-6'>
                        <div className='space-y-3'>
                            <p>CEO & Founder</p>
                            <p className='lg:text-3xl text-xl font-semibold'>Steve Rogers</p>
                        </div>

                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" data-slot="icon" className="w-10 h-10 transition-all opacity-0 group-hover:opacity-100 group-hover:duration-300">
                                <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className='rounded-xl group cursor-pointer card-wrapper col-span-1'>
                    <img src={team_2} alt='' className='group-hover:opacity-70 transition-all group-hover:duration-500'></img>

                    <div className='flex items-center justify-between mt-6'>
                        <div className='space-y-3'>
                            <p>CFO & Full Stack Dev</p>
                            <p className='lg:text-3xl text-xl font-semibold'>Godfather</p>
                        </div>

                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" data-slot="icon" className="w-10 h-10 transition-all opacity-0 group-hover:opacity-100 group-hover:duration-300">
                                <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className='rounded-xl group cursor-pointer card-wrapper col-span-1'>
                    <img src={team_3} alt='' className='group-hover:opacity-70 transition-all group-hover:duration-500'></img>

                    <div className='flex items-center justify-between mt-6'>
                        <div className='space-y-3'>
                            <p>CTO & Solidity Dev</p>
                            <p className='lg:text-3xl text-xl font-semibold'>Liza</p>
                        </div>

                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" data-slot="icon" className="w-10 h-10 transition-all opacity-0 group-hover:opacity-100 group-hover:duration-300">
                                <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                            </svg>
                        </div>
                    </div>
                </div>

                <div className='rounded-xl group cursor-pointer card-wrapper col-span-1'>
                    <img src={team_2} alt='' className='group-hover:opacity-70 transition-all group-hover:duration-500'></img>

                    <div className='flex items-center justify-between mt-6'>
                        <div className='space-y-3'>
                            <p>Advisor & Graphics Head</p>
                            <p className='lg:text-3xl text-xl font-semibold'>Alee</p>
                        </div>

                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" data-slot="icon" className="w-10 h-10 transition-all opacity-0 group-hover:opacity-100 group-hover:duration-300">
                                <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                            </svg>
                        </div>
                    </div>
                </div>

            </Slider>

            <div className="flex gap-6 justify-center pt-6">
                <button onClick={() => slider?.current?.slickPrev()} className="left-arrow bg-white-100 hover:bg-green-50 rounded-full p-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-black-100">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                    </svg>
                </button>

                <button onClick={() => slider?.current?.slickNext()} className="left-arrow bg-white-100 hover:bg-green-50 rounded-full p-2">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 text-black-100">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5L21 12m0 0l-7.5 7.5M21 12H3" />
                    </svg>
                </button>
            </div>

        </div>

    )


}

export default ImageSlider 